import "./Hero.scss";
import "aos/dist/aos.css";

import { Dimensions, StatusBar } from "react";

//import knowledge partners logos
import rug from "../Resources/Pics/partners/rug-large.svg";
import hanze from "../Resources/Pics/partners/hanze-large.svg";
import noord from "../Resources/Pics/partners/noord-large.svg";
import gemente from "../Resources/Pics/partners/vangroningen-green.svg";
import provincie from "../Resources/Pics/partners/provincie-groningen-green.svg";
import seatrade from "../Resources/Pics/partners/seatrade-main-white.png";
import oxfordPV from "../Resources/Pics/partners/oxford-green.svg";

import teamphoto from "../Resources/Pics/teamphoto/team23.webp";
// links to knowledge partners
const hanzeLink = "https://www.hanze.nl/nld";
const rugLink = "https://www.rug.nl";
const provincieLink = "https://www.provinciegroningen.nl/";
const gementeLink = "https://www.visitgroningen.nl/nl";
const seatradeLink = "https://www.seatrade.com";
const oxfordPVLink = "https://www.oxfordpv.com";

function Hero() {
  return (
    <div className="video-container overflow-hidden">
      {/* have a custom fallback image */}
      <video autoPlay loop muted playsInline poster={teamphoto}>
        <source
          src={require("../Resources/Videos/this_is_us.webm")}
          type="video/webm"
          playsinline="true"
        />
      </video>
      <span className="slogan caption">
        <span id="driven">driven</span>
        <span className="animate">
          <span id="to">to</span>
          <span id="get">get</span>
          <span id="t">t</span>
          <span id="her">her</span>
          <span id="last-e">e</span>
        </span>
      </span>

      <div className="container-flexible container-xl logos">
        <div className="row text-center w-100 justify-content-center justify-content-md-around">
          <div className="col-md-4 col-sm-6 d-flex justify-content-center vertical-align-logo">
            <a href={seatradeLink} target="_blank">
              <img src={seatrade} className="img-fluid" alt="seatrade"></img>
            </a>
          </div>

          <div className="col-md-4 col-sm-6 d-flex justify-content-center vertical-align-logo">
            <a href={rugLink} target="_blank">
              <img src={rug} className="img-fluid" alt="rug"></img>
            </a>
          </div>
          <div className="col-md-4 col-sm-6 d-flex justify-content-center vertical-align-logo">
            <a href={hanzeLink} target="_blank">
              <img src={hanze} className="img-fluid" alt="hanze"></img>
            </a>
          </div>
          <div className="row text-center w-100 justify-content-center justify-content-md-around">
            <div className="col-md-4 col-sm-6 d-flex justify-content-center vertical-align-logo">
              <a href={provincieLink} target="_blank">
                <img
                  src={provincie}
                  className="img-fluid"
                  alt="noordport"
                ></img>
              </a>
            </div>
            <div className="col-md-4 col-sm-6 d-flex justify-content-center vertical-align-logo">
              <a href={gementeLink} target="_blank">
                <img
                  src={gemente}
                  className="img-fluid"
                  alt="alfa-college"
                ></img>
              </a>
            </div>
            <div className="col-md-4 col-sm-6 d-flex justify-content-center vertical-align-logo">
              <a
                href={oxfordPVLink}
                target="_blank"
                style={{ filter: "invert(1)" }}
              >
                <img src={oxfordPV} className="img-fluid" alt="oxfordPV"></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
