import "./TeamScroll.scss";
// import AOS
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

// photos import
import Micha from "../Resources/Pics/team_photos_23_webp/micha.webp";
import Ricky from "../Resources/Pics/team_photos_23_webp/ricky.webp";
import Erik from "../Resources/Pics/team_photos_23_webp/erik.webp";
import Camiel from "../Resources/Pics/team_photos_23_webp/camiel.webp";
import Andreea from "../Resources/Pics/team_photos_23_webp/andreea.webp";
import Maha from "../Resources/Pics/team_photos_23_webp/maha.webp";
import Nina from "../Resources/Pics/team_photos_23_webp/nina.webp";
import Loeka from "../Resources/Pics/team_photos_23_webp/loeka.webp";
import Peter from "../Resources/Pics/team_photos_23_webp/peter.webp";
import Anna from "../Resources/Pics/team_photos_23_webp/anna.webp";
import Jakob from "../Resources/Pics/team_photos_23_webp/jakob.webp";
import Huan from "../Resources/Pics/team_photos_23_webp/huan.webp";
import Marko from "../Resources/Pics/team_photos_23_webp/marko.webp";
import Matthias from "../Resources/Pics/team_photos_23_webp/matthias.webp";
import Mourad from "../Resources/Pics/team_photos_23_webp/mourad.webp";
import Stan from "../Resources/Pics/team_photos_23_webp/stan.webp";
import Kees from "../Resources/Pics/team_photos_23_webp/kees.webp";
import Jochem from "../Resources/Pics/team_photos_23_webp/jochem.webp";
import Themba from "../Resources/Pics/team_photos_23_webp/themba.webp";
import Laura from "../Resources/Pics/team_photos_23_webp/laura.webp";
import Ayzo from "../Resources/Pics/team_photos_23_webp/ayzo.webp";
import Ian from "../Resources/Pics/team_photos_23_webp/ian.webp";
import Andrei from "../Resources/Pics/team_photos_23_webp/andrei.webp";
import Vlad from "../Resources/Pics/team_photos_23_webp/vlad.webp";
import Amy from "../Resources/Pics/team_photos_23_webp/amy.webp";
import Nieck from "../Resources/Pics/team_photos_23_webp/nieck.webp";

function TeamScroll() {
  var portraits = [
    Micha,
    Ricky,
    Erik,
    Camiel,
    Andreea,
    Maha,
    Nina,
    Loeka,
    Peter,
    Anna,
    Jakob,
    Huan,
    Marko,
    Matthias,
    Mourad,
    Stan,
    Kees,
    Jochem,
    Themba,
    Laura,
    Ayzo,
    Ian,
    Andrei,
    Vlad,
    Amy,
    Nieck,
  ];

  var portraitsList = portraits.map((portrait) => (
    <div className="slide">
      <img
        src={portrait}
        alt="portrait"
        className="portrait"
        width={"350rem"}
        effect="blur"
      />
    </div>
  ));
  return (
    <>
      <div
        data-aos="fade-up"
        className="section-title"
        data-aos-duration="1000"
      >
        <h1>
          Meet the <text className="emphasis"> /people </text> <br></br>
          <h2>
            That will <text className=""> get us </text> there
          </h2>
        </h1>
      </div>
      <div className="container-fluid team overflow-hidden">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-12 slider">
            <div className="slide-track">
              {portraitsList}
              {portraitsList}
              {portraitsList}
            </div>
          </div>
          <Link to="/team23">
            <span className="title">
              <h1 data-aos="fade-right" data-aos-duration="1000">
                TEAM{" "}
              </h1>
              <h1 data-aos="fade-left" data-aos-duration="1000">
                <text className="emphasis"> /23</text>
              </h1>
            </span>{" "}
          </Link>
        </div>
      </div>
    </>
  );
}

export default TeamScroll;
