import React from "react";
import CarsDisplay from "../../Cars/Cars";
import "./Cars.scss";
import GScars from "../../Resources/Pics/cars/GScars.webp";
import GLcars from "../../Resources/Pics/cars/GLcars.webp";

const Cars = () => {
  const YoutubeVideo = ({ videoId, aspectRatio = "16:9" }) => {
    const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&controls=0&mute=1&disablekb=1&playlist=${videoId}`;

    // Split the aspect ratio string into width and height
    const [width, height] = aspectRatio.split(":").map(Number);

    // Calculate padding bottom percentage
    const paddingBottom = (height / width) * 100;

    const wrapperStyle = {
      position: "relative",
      paddingBottom: `${paddingBottom}%`, // Use calculated paddingBottom
      height: 0,
    };

    const iframeStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    };

    return (
      <div style={wrapperStyle}>
        <iframe
          style={iframeStyle}
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; loop; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const duration = 1000;

  return (
    <div>
      <CarsDisplay />
      <div
        id="GreenThunder"
        data-aos="fade-left"
        data-aos-duration={duration}
        className="special container simple-section mt-5 mb-5"
      >
        <div className="row simple-section-header">
          <h1>
            GREEN <text className="emphasis"> /THUNDER </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <div className="col order-sm-first">
            <div className="row">
              <h3>Description</h3>
              <p>
                Green Thunder is the newest solar-powered racing car developed
                by the Top Dutch Solar Racing team, a student-led initiative
                from Groningen, the Netherlands. It is the third iteration of
                their innovative solar cars, following the successful Green
                Lightning and Green Spirit models. Green Thunder is designed
                with a singular goal in mind - to claim victory at the
                prestigious Bridgestone World Solar Challenge. This latest model
                encapsulates the lessons learned from its predecessors while
                innovating on their strengths. The car incorporates the speed
                and efficiency demonstrated by Green Lightning, which managed to
                secure a pole position start and a fourth-place finish at the
                team's debut in the 2019 Bridgestone World Solar Challenge.
                Furthermore, it builds on the resilience and reliability shown
                by Green Spirit, the team's second solar car that was revealed
                in 2021.
              </p>
              <br></br>
              <p>
                Green Thunder represents the team's continuous commitment to
                sustainable innovations, technology, and engineering excellence.
                It's a testament to the students' relentless drive to push
                boundaries in solar car technology. The car showcases the team's
                evolution in design, performance, and energy efficiency, setting
                a new benchmark for solar-powered vehicles. The Top Dutch Solar
                Racing team is optimistic that Green Thunder has what it takes
                to emerge victorious in the upcoming Bridgestone World Solar
                Challenge.
              </p>
            </div>

            <div className="row">
              <div className="col">
                {" "}
                <div className=" row simple-section-body">
                  <h3>
                    Meet <text className="emphasis"> /GREEN THUNDER </text>
                  </h3>
                  <div className="row">
                    <YoutubeVideo videoId="5OdP9Ft4jA0" aspectRatio="16:9" />
                  </div>
                </div>
              </div>
              <div className="col">
                {" "}
                <div className="row simple-section-body">
                  <h3>
                    Watch the <text className="emphasis"> /Reveal </text>
                  </h3>
                  <div className="row">
                    <YoutubeVideo videoId="MHcIGqvMa4o" aspectRatio="16:9" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="GreenSpirit"
        data-aos="fade-right"
        data-aos-duration={duration}
        className="special container simple-section mt-5 mb-5"
      >
        <div className="row simple-section-header">
          <h1>
            GREEN <text className="emphasis"> /SPIRIT </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <div className="col-6 col-md-8 order-sm-first">
            <div className="row-6 row-md-8">
              <h3>Description</h3>
              <p>
                With the Bridgestone World Solar Challenge 2021 being cancelled,
                there was an uncertainty whether or not to build a solar car.
                Luckily, a large group of teams put their heads together and
                managed to organize the Moroccan Solar Challenge 2021!
              </p>
              <br></br>
              <p>
                Racing in this challenge was our beloved Green Spirit, the
                second Top Dutch solar car. It is a 4-wheeled mono-hull solar
                car, hand-build from scratch with carbon and glass fibre. With
                an 100% self-made electrical system and a nearly 100% self
                developed mechanical system, we put all our knowledge and skills
                into this car.
              </p>
            </div>
            <div className="row-6 row-md-4">
              <img src={GScars} alt="Green Spirit" className="img-fluid"></img>
            </div>
          </div>
          <div className="col-6 col-md-4 order-sm-last">
            <h3>Specifications</h3>
            <div class="spec-content">
              <div class="spec-container">
                <div class="spec-item">
                  <p>
                    <strong>Year of construction</strong>
                  </p>
                  <p>2021</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Weight</strong>
                  </p>
                  <p>200 kg</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Dimensions</strong>
                  </p>
                  <p>1.3m x 1.1m x 5m</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Hours of work</strong>
                  </p>
                  <p>68.500</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Top speed</strong>
                  </p>
                  <p>110 km/h</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Motor</strong>
                  </p>
                  <p>Power: 1kW nominal, 10kW peak</p>
                  <p>Efficiency: 97,8%</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Solar cells</strong>
                  </p>
                  <p>
                    Silicon Heterojunction Cells with Smart Wire Connection
                    Technology
                  </p>
                  <p>
                    Surface: 4 m<sup>2</sup>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="GreenLighting"
        data-aos="fade-left"
        data-aos-duration={duration}
        className="special container simple-section mt-5 mb-5"
      >
        <div className="row simple-section-header">
          <h1>
            GREEN <text className="emphasis"> /LIGHTNING </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <div className="col-6 col-md-8 order-sm-first">
            <div className="row-6 row-md-8">
              <h3>Description</h3>
              <p>
                Green Lightning was the first Top Dutch solar car and was the
                result of two years of engineering, from 2017 until 2019. The
                light, mono-hulled, carbon body has enough space for innovative
                parts that have been built from scratch.
              </p>
              <br></br>
              <p>
                Two examples of these custom parts are the four wheel steering
                system and composite leaf springs. By using the four wheel
                steering system, the solar car can drive at an angle, which
                reduces aerodynamical resistance. The composite leaf springs
                contribute to the stability of the vehicle.
              </p>
            </div>
            <div className="row-6 row-md-4">
              <img
                src={GLcars}
                alt="Green Lighting"
                className="img-fluid"
              ></img>
            </div>
          </div>
          <div className="col-6 col-md-4 order-sm-last">
            <h3>Specifications</h3>
            <div class="spec-content">
              <div class="spec-container">
                <div class="spec-item">
                  <p>
                    <strong>Year of construction:</strong>
                  </p>
                  <p>2019</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Weight</strong>
                  </p>
                  <p>150 kg</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Dimensions</strong>
                  </p>
                  <p>1.2m x 1m x 5m</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Hours of work</strong>
                  </p>
                  <p>63.000</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Top Speed</strong>
                  </p>
                  <p>140 km/h</p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Solar cells</strong>
                  </p>
                  <p>Single junction GaAs</p>
                  <p>
                    Surface: 3,56 m<sup>2</sup>
                  </p>
                </div>
                <div class="spec-item">
                  <p>
                    <strong>Motor</strong>
                  </p>
                  <p>Power: 2kW</p>
                  <p>Efficiency: 96%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cars;
