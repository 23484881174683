import styled from 'styled-components';

export const Heading = styled.h1`
    text-align: right;
    color: green;
`;

export const Content = styled.div`
    overflowY: scroll;
    height: 2500px;
`;

export const Button = styled.div`
    position: fixed;
    width: 100%;
    left: 85%;
    bottom: 3cm;
    height: 20px;
    font-size: 3rem;
    z-index: 100;
    cursor: pointer;
    color: #00BB31;
`

export const Button2 = styled.div`
    position: fixed;
    width: 100%;
    left: 85%;
    bottom: 2cm;
    height: 20px;
    font-size: 3rem;
    z-index: 100;
    cursor: pointer;
    color: #1734a0;
`
