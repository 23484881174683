import "./Cars.scss";
import GL from "../Resources/Pics/cars/GL_2.webp";
import GS from "../Resources/Pics/cars/GS_2.webp";
// import AOS
import "aos/dist/aos.css";
import { HashLink } from "react-router-hash-link";

function Cars() {
  // Define the animation duration
  const duration = 1000;

  return (
    <>
      {" "}
      <div
        data-aos="fade-up"
        className="section-title"
        data-aos-duration={duration}
      >
        <h1>
          THE <text className="emphasis"> /cars </text> <br></br>
          <h2>
            That <text className=""> got us </text> there
          </h2>
        </h1>
      </div>
      <div className="d-flex justify-content-between bg-transparent overflow-hidden">
        <div
          className="left-to-right container "
          data-aos="fade-right"
          data-aos-duration={duration}
        >
          <div className="row align-items-center car-info right h-100">
            <div className="col-12 col-md-6 ">
              {" "}
              <h1 className="">Green Lightning</h1>
              <h3 className="">
                <text className="emphasis">
                  /4<sup>th</sup>{" "}
                </text>
                place in the BWSC 2019
              </h3>
              <h3 className="">
                <text className="emphasis">
                  /3<sup>rd</sup>{" "}
                </text>
                place in the iESC 2020
              </h3>
              <h3 className="">
                <text className="emphasis">
                  /5<sup>th</sup>{" "}
                </text>
                place in the iESC 2022
              </h3>
            </div>

            <div className="col-12 col-md-6">
              {" "}
              <HashLink to="/cars#GreenLighting">
                <img src={GL} alt="GL" className="img-fluid float-right" />
              </HashLink>
            </div>
          </div>
        </div>

        <div
          className="right-to-left container"
          data-aos="fade-left"
          data-aos-duration={duration}
        >
          <div className="row align-items-center car-info left h-100">
            <div className="col-12 col-md-6 order-md-first order-last">
              {" "}
              <HashLink to="/cars#GreenSpirit">
                <img src={GS} alt="GS" className="img-fluid float-left" />
              </HashLink>
            </div>
            <div className="col-12 col-md-6 ">
              {" "}
              <h1>Green Spirit</h1>
              <h3>
                <text className="emphasis">
                  /4<sup>th</sup>{" "}
                </text>{" "}
                place in Solar Challenge Morocco 2021
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cars;
