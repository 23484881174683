// Page to display a full screen video player from youtube

import "./livestream.scss";
import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import Countdown from "react-countdown";
import BottomScroll from "../../Util/BottomScroll/BottomScroll";

const Livestream = () => {
  const [countdownCompleted, setCountdownCompleted] = useState(false);

  const targetDate = new Date("2023-06-16T10:20:00+0200"); // CEST timezone
  const currentDate = new Date();

  // If the current date is after the target date, we've already completed the countdown
  useEffect(() => {
    // console.log(currentDate);
    if (currentDate > targetDate) {
      setCountdownCompleted(true);
    }
  }, [currentDate, targetDate]);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return null;

    if (completed) {
      setCountdownCompleted(true);
      return null;
    } else {
      return (
        // check if there are days left
        <>
          <div className="countdown-overlay">
            {" "}
            <br></br>
            <span className="countdown">
              {days > 0 ? `${days} days and ` : ""}
              {hours.toString().padStart(2, "0")}:
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")} hours, until the livestream
              starts!
            </span>
            <BottomScroll />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="youtube-container">
        {!countdownCompleted && (
          <Countdown date={targetDate} renderer={renderer} />
        )}
        <YouTube
          className="youtube-container"
          videoId="MHcIGqvMa4o"
          opts={opts}
          onReady={onReady}
        />
      </div>
    </>
  );
};

export default Livestream;
