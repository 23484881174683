import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./AboutUs.scss";
import AOS from "aos";

function SmallAboutUs() {
  useEffect(() => {
    AOS.init();
  }, []);
  const YoutubeVideo = ({ videoId }) => {
    const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&controls=0&mute=1&disablekb=1&playlist=${videoId}`;

    return (
      <div>
        <iframe
          width="100%"
          height="220"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; loop; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  return (
    <>
      <div
        data-aos="fade-up"
        className="section-title"
        data-aos-duration="1000"
      >
        <h1>
          Who are <text className="emphasis">/we?</text> <br></br>
        </h1>
      </div>
      <div
        data-aos="fade-up"
        className="d-flex container-fluid smallabout overflow-hidden section "
        data-aos-duration="1000"
      >
        <div className="row simple-section-header align-items-center rounded-4">
          <h3>
            Join our ambitious young team of international and diverse students
            from the Netherlands as we design, build, and race our cutting-edge
            solar car in the rugged outback of Australia. We’re ready to leave
            our mark and build our legacy by harnessing the power of the sun to
            propel our vehicle to new heights of efficiency and performance. Get
            ready for an adventure of a lifetime as we take on the <a className="link-to-page" href="https://www.worldsolarchallenge.org/" target="_blank">Bridgestone World Solar Challenge</a> in the Australian
            outback and push the boundaries of sustainable transportation, all
            while leaving a zero-emissions footprint.
          </h3>
          <br></br>
          <div className="row simple-section-body">
            <div className="col-md-4 ">
              <YoutubeVideo videoId="A1zilq_5Efo" />
            </div>

            <div className="col-md-4 ">
              <YoutubeVideo videoId="z7UTankt1hY" />{" "}
            </div>
            <div className="col-md-4">
              <YoutubeVideo videoId="EvAzDjG17Lo" />{" "}
            </div>
          </div>
          <div className="row findbutton">
            <Link to="/about">
              <button className="btn btn-primary-green">About Us</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SmallAboutUs;
