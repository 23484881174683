import { useEffect } from "react";
import "./Sponsors.scss";
// import AOS
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

//import the partners
// import greatwaves from "../Resources/Pics/partners/Greatwaves.svg";
// Main partner
import seatrade from "../Resources/Pics/partners/seatrade-main-color.png";
// green partners
import rug from "../Resources/Pics/partners/rug-large.svg";
import hanze from "../Resources/Pics/partners/hanze-large.svg";
import viro from "../Resources/Pics/partners/viro-green.svg";
import provGron from "../Resources/Pics/partners/provincie-groningen-green.svg";
import vanGroningen from "../Resources/Pics/partners/vangroningen-green.svg";
import oxfordPV from "../Resources/Pics/partners/oxford-green.svg";
// platinum partners
import infinite from "../Resources/Pics/partners/infinite-plat.svg";
import loxam from "../Resources/Pics/partners/loxam-plat.svg";
import greatwaves from "../Resources/Pics/partners/greatwaves-plat.svg";
// Knowledge partners
import noord from "../Resources/Pics/partners/noord-large.svg";
import alfa from "../Resources/Pics/partners/alfa-large.svg";
// import ROC from "../Resources/Pics/partners/ROC-large.svg";
import firda from "../Resources/Pics/partners/firda-knowledge.svg";
// import ansys from "../Resources/Pics/partners/ansys-plat.svg";
import oldernburg from "../Resources/Pics/partners/oldernburg-plat.svg";
// gold partners
import indi from "../Resources/Pics/partners/indi-gold.svg";
import nedcam from "../Resources/Pics/partners/nedcam-gold.svg";
import RDW from "../Resources/Pics/partners/RDW-gold.svg";
import vanmossel from "../Resources/Pics/partners/vanmossel-gold.svg";

// partner links
const provGronLink = "https://www.provinciegroningen.nl";
const visitGronLink =
  "https://www.visitgroningen.nl/nl/blogs/er-gaat-niets-boven-groningen";
const seatradeLink = "https://www.seatrade.com";

function Sponsors() {
  useEffect(() => {
    AOS.init();
  }, []);

  // Define the animation duration
  const duration = 1000;
  return (
    <>
      <div
        data-aos="fade-up"
        className="section-title"
        data-aos-duration={duration}
      >
        <h1>
          THE <text className="emphasis"> /partners </text> <br></br>
          <h2>
            THAT HELP US <text className=""> MAKE IT HAPPEN </text>
          </h2>
        </h1>
      </div>
      <div className="container partners overflow-hidden">
        <div className="row gx-4 gy-0 ">
          <div
            className="col main-partner text-center info-bar d-flex flex-column justify-content-center align-items-center rounded-4"
            data-aos="zoom-out-up"
            data-aos-duration={duration}
          >
            <h1>
              <text className="emphasis">/Main</text> Partner
            </h1>
            <a href={seatradeLink} target="_blank">
              <img src={seatrade} className="img-fluid" alt="seatrade"></img>
            </a>
            <div className="motto-seatrade">
              <p>ALWAYS. MOVING. FORWARD.</p>
            </div>
          </div>
        </div>
        <br></br>
        {/* </div>
      <div className="container partners overflow-hidden"> */}

        <div className="row gx-4 ">
          <div className="col ">
            <div className="row gy-5 gx-0 ">
              <div
                className="Green-partner rounded-4"
                data-aos="zoom-out-down"
                data-aos-duration={duration}
              >
                <h1>Green partners</h1>
                <div className="col ">
                  <a href="https://www.rug.nl" target="_blank" rel="noreferrer">
                    <img
                      src={rug}
                      class="img-fluid"
                      alt="https://www.rug.nl"
                    ></img>
                  </a>
                  <a
                    href="https://www.hanze.nl/nld"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={hanze} class="img-fluid" alt="Prov Gron"></img>
                  </a>
                  <a
                    href="https://www.provinciegroningen.nl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={provGron} class="img-fluid" alt="Prov Gron"></img>
                  </a>
                </div>
                <div className="col ">
                  <a
                    href="https://www.visitgroningen.nl/nl"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={vanGroningen}
                      class="img-fluid"
                      alt="van Groningen"
                    ></img>
                  </a>
                  <a
                    href="https://www.oxfordpv.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{ filter: "invert(1)" }}
                  >
                    <img
                      src={oxfordPV}
                      class="img-fluid oxf"
                      alt="Oxford PV"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="row gy-5 gx-2 rwo-cols-2 h-auto justify-content-between margin-bottom-row">
              <div
                className="col Gold-partner rounded-4 align-middle"
                data-aos="zoom-in-right"
                data-aos-duration={duration}
              >
                <div className="row g-1">
                  <h1>Knowledge partners</h1>
                  <a
                    href="https://www.alfa-college.nl/groningen"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={alfa} alt="Alfa" className="img-fluid pt-2"></img>
                  </a>
                  <a
                    href="https://noorderpoort.nl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={noord}
                      alt="https://noorderpoort.nl/"
                      className="img-fluid pt-4"
                    ></img>
                  </a>
                  <a
                    href="https://www.firda.nl/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ filter: "invert(1)" }}
                  >
                    <img
                      src={firda}
                      alt="firda"
                      className="img-fluid oxf mb-3"
                    ></img>
                  </a>
                </div>
              </div>

              <div
                className="col Platinum-partner rounded-4 align-middle"
                data-aos="zoom-in-left"
                data-aos-duration={duration}
              >
                <div className="row g-1">
                  <h1>Platinum partners</h1>
                  <a
                    href="https://www.greatwaves.nl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={greatwaves}
                      alt="Greatwaves"
                      className="img-fluid"
                    ></img>
                  </a>
                  <a
                    href="https://www.infinite.nl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={infinite}
                      alt="Infinite"
                      className="img-fluid"
                    ></img>
                  </a>
                  <a
                    href="https://www.loxam.nl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={loxam} alt="Loxam" className="img-fluid"></img>
                  </a>
                  <a
                    href="https://www.oldenburgerfritom.nl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={oldernburg}
                      alt="Oldenburger"
                      className="img-fluid"
                    ></img>
                  </a>
                  <a
                    href="https://www.viro-group.com/nl/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={viro} alt="Viro" className="img-fluid"></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="row gy-5  gx-0 ">
              <div
                className="col text-center info-bar d-flex flex-column justify-content-center align-items-center rounded-4"
                data-aos="zoom-out-right"
                data-aos-duration={duration}
              >
                <h1>
                  We couldn't be here without the support of our sponsors and
                  partners
                </h1>
                <Link to="/partners">
                  <button className="btn btn-primary-green">
                    All Partners
                  </button>
                </Link>
                <button
                  onClick={() =>
                    (window.location =
                      "mailto:acquisition@solarracinggroningen.nl")
                  }
                  className="btn-primary-green btn"
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sponsors;
